<template>
  <div class="ui-input-search ui-inset ui-breathe">
    <ui-icon
      class="ui-input-search-icon"
      size="22"
      value="mdi:magnify"
    ></ui-icon>

    <input
      type="text"
      :placeholder="placeholder"
      v-model="innerValue"
      @keyup="debounceInput"
    />
  </div>
</template>

<script>
import { UiIcon } from '../../components';

export default {
  name: 'ui-input-search',

  components: {
    UiIcon,
  },

  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },

    placeholder: {
      type: String,
      required: false,
      default: null,
    },

    debounce: {
      type: Number,
      required: false,
      default: 400,
    },

    minLength: {
      type: Number,
      required: false,
      default: 3,
    },
  },

  data() {
    return {
      innerValue: '',
      timer: null,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.innerValue = newValue ? newValue.trim() : '';
      },
    },
  },

  methods: {
    focus() {
      return this.$nextTick(() => this.$el.querySelector('input').select());
      // return this.$nextTick(() => this.$el.querySelector('input').focus());
      // return this.$el.querySelector('input').focus();
    },

    debounceInput() {
      clearTimeout(this.timer);

      let value = this.innerValue.trim();
      if (!value.length) {
        this.$emit('input', null);
        return;
      }

      this.timer = setTimeout(() => {
        if (value.length >= this.minLength) {
          this.$emit('input', value);
        }
      }, this.debounce);
    },
  },
};
</script>

<style lang="scss">
.ui-input-search {
  display: flex;

  .ui-input-search-icon {
    color: inherit;
    margin: 0 8px;
  }

  & > input {
    flex: 1;

    display: block;
    width: 100%;
    outline: none;
    border: none;
    color: inherit;
    background-color: transparent;

    font-size: 1em;
    padding: 8px;

    &::placeholder,
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder {
      color: #9fa4aa;
    }
  }
}
</style>